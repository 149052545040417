import "./App.css";
import MainComponent from "./component/MainComponent/MainComponent";

function App() {
  return (
    <>
      <MainComponent />
    </>
  );
}

export default App;

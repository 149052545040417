import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SpinnerComponent from "../Module/SpinnerComponent/SpinnerComponent";

const HomeComponent = lazy(() =>
  import("../Features/HomeComponent/HomeComponent")
);
const AboutUsComponent = lazy(() =>
  import("../Features/AboutUsComponent/AboutUsComponent")
);
const ProductsComponent = lazy(() =>
  import("../Features/ProductsComponent/ProductsComponent")
);
const FaqComponent = lazy(() =>
  import("../Features/FaqComponent/FaqComponent")
);
const ContactUsComponent = lazy(() =>
  import("../Features/ContactUsComponent/ContactUsComponent")
);
const DetailedProductDetailsComponent = lazy(() =>
  import(
    "../Features/DetailedProductDetailsComponent/DetailedProductDetailsComponent"
  )
);
const SustainabilityComponent = lazy(() =>
  import("../Features/SustainabilityComponent/SustainabilityComponent")
);
const TermsAndConditionsComponent = lazy(() =>
  import("../Features/TermsAndConditionsComponent/TermsAndConditionsComponent")
);
const PrivacyAndPolicyComponent = lazy(() =>
  import("../Features/PrivacyAndPolicyComponent/PrivacyAndPolicyComponent")
);
const ErrorPage = lazy(() => import("../Features/ErrorPage/ErrorPage"));

const MainComponent = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<SpinnerComponent />}>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/home" element={<HomeComponent />} />
          <Route path="/aboutus" element={<AboutUsComponent />} />
          <Route path="/products" element={<ProductsComponent />} />
          <Route
            path="/aboutproduct"
            element={<DetailedProductDetailsComponent />}
          />
          <Route path="/faq" element={<FaqComponent />} />
          <Route path="/contactus" element={<ContactUsComponent />} />
          <Route path="/sustainability" element={<SustainabilityComponent />} />
          <Route
            path="/termsandconditions"
            element={<TermsAndConditionsComponent />}
          />
          <Route
            path="/privacypolicy"
            element={<PrivacyAndPolicyComponent />}
          />

          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default MainComponent;
